import { DocumentNode } from "graphql";
import client from "./client";
import browserLogger from '../../../settings/browser-logger';
import * as Sentry from '@sentry/nextjs';

export interface MutationResult {
    success: boolean;
    errorMessage: string | null;
    data: any;
}

export const dtoneMutation = (
    mutation: DocumentNode,
    variables: Record<string, any> = {}
): Promise<MutationResult> => {
    return new Promise(resolve => {
        let objToBeReturned = { success: true, errorMessage: null, data: null };
        client
            .mutate({
                mutation,
                variables
            })
            .then(({ data }) => {
                objToBeReturned.data = data;
                resolve(objToBeReturned);
            })
            .catch(err => {
                const { graphQLErrors } = JSON.parse(JSON.stringify(err));
                const errorMessage = (Array.isArray(graphQLErrors) && graphQLErrors.length && graphQLErrors[0]?.message) ? graphQLErrors[0].message : "Something went wrong";
                browserLogger.error(
                    err.message,
                    {
                        error: err,
                        function: 'graphqlMutation',
                    },
                );
                Sentry.captureException(err, (scope) => {
                    scope.clear();
                    scope.setContext("additionalData", {
                      mutation: mutation && mutation?.definitions && mutation?.definitions?.length > 0 && mutation?.definitions[0]['name'] && mutation?.definitions[0]['name']?.value,
                    });
                    return scope;
                });
                objToBeReturned = { ...objToBeReturned, success: false, errorMessage };
                resolve(objToBeReturned);
            });
    });
}