import { getServices, getCountries, GetWalletBalance, createTransactionDTQuery, conversionToCAD,authorizeTransactionPaymentChallenge, getCustomerCards, generateTransactionReceipt, getCountriesPublic, getOperatorsPublic, getServicesPublic, CheckTransactionVisitStatus,verifyCardMicroDepositQuery , sendCardMicroDepositRequestQuery} from './graphql.graphql';
import {
  SET_TRANSACTION_DETAILS_DT,
  SET_TRANSFER_AMNT_DT,
  SET_PAYMENT_TYPE_DT,
  SET_WALLET_BAL_DT,
  SET_TRANSACTION_STATUS_DT,
  SET_TRANSACTION_PAGE_VISITED,
  SET_DT_TRANS_FLOW,
  CLEAR_TRANS_STATE_DT,
  SET_REWARDS_AMNT_DT,
  SET_FINAL_TRANSFER_AMNT_DT,
  SET_CARD_DETAIL_DT,
  SET_CHALLENGE_PARAMS,
  SET_TRANSACTION_UNIQUE_ID_DT,
  SET_REDIRECT_ROUTE,
  SET_WALLET_BALANCE,
  SET_PAYMENT_ERROR
} from './actionTypes';
import { dtoneQuery } from '../../settings/apollo/dtone/query';
import { dtoneMutation } from '../../settings/apollo/dtone/mutation';
import { apiMainQuery } from '../../settings/apollo/api/main/query';

export const setTransferAmountDT = (payload) => ({
  type: SET_TRANSFER_AMNT_DT,
  payload,
});

export const setTransactionStatus = (payload) => ({
  type: SET_TRANSACTION_STATUS_DT,
  payload
})

export const setTransactionDetails = (payload) => ({
  type: SET_TRANSACTION_DETAILS_DT,
  payload,
});

export const setTransactionPayType = (payload) => ({
  type: SET_PAYMENT_TYPE_DT,
  payload
});

export const setWalletBalanceDT = (payload) => ({
   type:SET_WALLET_BAL_DT,
   payload
})

export const setTransPageVisitedDT = (payload) => ({
    type:SET_TRANSACTION_PAGE_VISITED,
    payload
})

export const setTransFlowDT = (payload) => ({
    type:SET_DT_TRANS_FLOW,
    payload
})

export const setRewardsAmountDT = (payload) => ({
  type:SET_REWARDS_AMNT_DT,
  payload
})

export const setFinalTransferAmountDT = (payload) => ({
  type:SET_FINAL_TRANSFER_AMNT_DT,
  payload
})

export const setAvailBalance = (payload) => ({
  type: SET_WALLET_BALANCE,
  payload
})

export const clearTransStateDT = () => ({
    type:CLEAR_TRANS_STATE_DT
})

export const setCardDetail = (payload) => ({
  type: SET_CARD_DETAIL_DT,
  payload
})

export const setTransUniqueId = (payload) => ({
  type: SET_TRANSACTION_UNIQUE_ID_DT,
  payload
})

export const setChallengeParams = (payload) => ({
  type: SET_CHALLENGE_PARAMS,
  payload
})

export const setRedirectRoute = (payload) => ({
  type: SET_REDIRECT_ROUTE,
  payload
})

export const setPaymentError = (payload) => ({
  type: SET_PAYMENT_ERROR,
  payload
})

export const convertToCAD = (destinationAmount,productId) => dtoneQuery(conversionToCAD, { destinationAmount,productId })

export const getServicesQuery = () => dtoneQuery(getServices);

export const getServicesPublicQuery = () => dtoneQuery(getServicesPublic);

export const getCountriesQuery = (serviceId, pageOptions = { pagination : { currentPage: 1, rowsPerPage: 500 } }) => dtoneQuery(getCountries, { serviceId, pageOptions });

export const getWalletBalance = (business_unique_id) => dtoneQuery(GetWalletBalance, { business_unique_id });

export const createTransaction = (transaction,payment,businessUniqueId,idempotencyKey) => dtoneMutation(createTransactionDTQuery,{ transaction,payment,businessUniqueId,idempotencyKey })

export const authorizeTransaction = (card,transactionUniqueId) => dtoneMutation(authorizeTransactionPaymentChallenge, { card,transactionUniqueId })

export const getCustomerCardsDT = (businessUniqueId) => dtoneQuery(getCustomerCards, { businessUniqueId })

export const generateDTOneTransactionReceipt = (tranUniqueId) => dtoneQuery(generateTransactionReceipt, { tranUniqueId })

export const getCountriesPublicQuery = (serviceId, pageOptions = { pagination : { currentPage: 1, rowsPerPage: 500 } }) => dtoneQuery(getCountriesPublic, { serviceId, pageOptions })

export const getOperatorsByServiceAndCountryPublic = (countryId, serviceId) => dtoneQuery(getOperatorsPublic, { countryId, serviceId })

export const checkServiceVisit = (requestType) => apiMainQuery(CheckTransactionVisitStatus, { request_type: requestType })

export const sendCardMicroDepositRequest = params => dtoneMutation(sendCardMicroDepositRequestQuery, params);

export const  verifyCardMicroDeposit = params => dtoneMutation(verifyCardMicroDepositQuery, params); 