import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { store } from '../../../redux/store';
import { setToken } from '../../../redux/auth/actions';
import { logout } from '../../../redux/auth/actions';

const GRAPHQL_URL = `${process.env.REACT_APP_DTONE_API}/graphql`;
const CUSTOMER_COOKIE_NAME = 'cp-token';

const authHeaderLink = setContext(async () => {
    const auth = store ? store.getState().auth : null;
    if (auth) {
        const { isAuthenticated, publicIdentifier, cp_session_token } = auth;
        const { csrfToken } = await setToken();

        const headers = {
            'CSRF-Token': csrfToken,
            'APP-VERSION': '1.0.0',
            'DEVICE-TYPE': 'CP'
        };

        if (cp_session_token) {
            headers[CUSTOMER_COOKIE_NAME] = `Bearer ${cp_session_token}`;
            headers['cp_session_token'] = `Bearer ${cp_session_token}`;
        }

        if (!isAuthenticated) headers['Authorization'] = `Bearer ${publicIdentifier}`;

        return ({ headers });
    }
});

const afterwareLink = new ApolloLink((operation, forward) => {
    let context = null;
    let headers = null;
    return forward(operation).map((response) => {
        context = operation.getContext();
        ({ response: { headers } } = context);
        return response;
    });
});

const logoutLink = onError(({ networkError }) => {
    if (networkError) {
        const statusCode = networkError['statusCode'];
        if ([403].includes(statusCode)) store && store.dispatch(logout(false, true, null));
    }
});

const httpLink = new HttpLink({ uri: GRAPHQL_URL, credentials: 'include' });

const link = ApolloLink.from([authHeaderLink, afterwareLink, logoutLink, httpLink]);

const isBrowser = typeof window !== 'undefined';

const client = new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    link,
    cache: new InMemoryCache({
        addTypename: false
    }).restore({})
});

export default client;
